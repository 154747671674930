var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button update",class:{ disableButton: _vm.isDisable },staticStyle:{},style:([
    _vm.float === 'right'
      ? { float: 'right' }
      : _vm.float === 'left'
      ? { float: 'left' }
      : 'float:none',
  ]),on:{"click":_vm.updateUserDetails}},[_c('span',{staticClass:"d-flex ProximaNovaSemiBold p-3",staticStyle:{"justify-content":"space-between"}},[_vm._v(" "+_vm._s(_vm.buttonText)+" "),(_vm.buttonLoader == 'normal')?_c('i',{staticClass:"fas fa-arrow-right ml-5 pl-4 d-flex",staticStyle:{"align-items":"center"}}):(_vm.buttonLoader == 'loader')?_c('div',{staticClass:"loaderupdate ml-5"}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }