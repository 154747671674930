<template>
  <div>
    <header class="header" :class="{
      'bg-class': isStartNewModal,
      'bg-white': nav === 'finish',
      header_z_index: nav === 'finish',
      header_z_index_more: nav !== 'finish',
    }">
      <div class="menuList" style="justify-content: space-between">
        <div class="d-flex" style="align-items: center">
          <div class="header__inner" style="text-align: center">
            <div class="header__logo" @click="gotoHome" style="cursor: pointer; width: 115px">
              <span>
                <img src="../assets/login_logodemo.png" alt height="58px" width="46px" />
              </span>
            </div>
          </div>
          <div class="header__inner" :style="
            this.$route.meta.navItem === 'Solution'
              ? 'border-bottom: 5px solid #050505'
              : 'border-bottom:0px;'
          " :class="[
  { 'menu-active': nav === 'solution' },
  { noHover: nav !== '' && nav !== 'finish' },
]">
            <w-menu-item v-if="hasUserAccess" :dropdown="true" :dropdownList="solutionDropdown" :text="'Solutions'"
              :icon="
                $route.meta.navItem === 'Solution' ? 'solutions' : 'solutions-active'
              " :nav="'solution'" ref="menuItem" @SelectedMenu="selectedMenu($event)">
            </w-menu-item>
          </div>
          <div v-if="noDataConnection" class="header__inner" :class="[
            { 'menu-active': nav === 'Data connection' },
            { noHover: nav !== '' && nav !== 'finish' },
          ]" :style="
  this.$route.meta.navItem === 'Data connection'
    ? 'border-bottom: 5px solid #050505'
    : 'border-bottom:0px;'
">
            <div v-if="!showZeeProduct">
              <w-menu-item v-if="hasUserAccess" :dropdown="true" :dropdownList="dataConnection"
                :text="'Data connection'" :icon="
                  $route.meta.navItem === 'Data connection'
                    ? 'data-connection-active'
                    : 'data-connection'
                " :nav="'Data connection'" ref="menuItem" class="uploadData" @SelectedMenu="selectedMenu($event)">
              </w-menu-item>
            </div>
          </div>
          <!--<div
          class="header__inner"
          :class="{ 'menu-active': nav === 'uploaddata' }"
           :style="
            this.$route.meta.navItem === 'upload-data'
              ? 'border-bottom: 5px solid #050505'
              : 'border-bottom:0px;'
          " >        
           <w-menu-item
            :dropdown="true"
            :dropdownList="uploadDataDropdowns"
            :text="'Upload Data'"
            :icon="'uploadData'"
            :nav="'uploaddata'"
            ref="menuItem"
            class="uploadData"
            @SelectedMenu="selectedMenu($event)"
          >
          </w-menu-item>
        </div>-->
          <div v-if="!isItdUser" class="header__inner d-none" :class="[
            { 'menu-active': nav === 'savedplan' },
            { noHover: nav !== '' && nav !== 'finish' },
          ]" :style="
  $route.meta.navItem === 'Saved Plan'
    ? 'border-bottom: 5px solid #050505'
    : 'border-bottom:0px;'
">
            <div v-if="!showZeeProduct">
              <w-menu-item v-if="userHasTheAccess" :dropdown="false" :text="'Saved Plans'"
                :icon="$route.name === 'SavedPlans' ? 'savedPlanActive' : 'savedplan'"
                @SelectedMenu="selectedMenu($event)">
              </w-menu-item>
            </div>
          </div>
          <div class="header__inner" :style="
            this.$route.meta.navItem === 'Demo'
              ? 'border-bottom: 5px solid #050505'
              : 'border-bottom:0px;'
          " :class="[
  { 'menu-active': nav === 'demo' },
  { noHover: nav !== '' && nav !== 'finish' },
]">
            <w-menu-item :dropdown="true" :dropdownList="demoDropdowns" :text="'Demo'"
              :icon="$route.meta.navItem === 'Demo' ? 'active-demo' : 'demo'" :nav="'demo'" ref="menuItem"
              @SelectedMenu="selectedMenu($event)">
            </w-menu-item>
          </div>
        </div>

        <div class="d-flex">
          <div style="display: flex; height: 70px; align-items: center">
            <span v-if="!showZeeProduct && !enableSearch" @click="showModal" :class="[
              { 'active-right-menu': nav === 'notifications' },
              { noHover: nav !== '' && nav !== 'finish' },
            ]" title="Notification" class="pl-4 pr-4">
              <button type="button" class="btn p-0 m-0" style="width: 25px">
                <img src="../assets/Icons/notification.svg" alt="" />
              </button>
              <span class="badge badge-light" v-if="notifications.length !== 0">.</span>
            </span>
            <span v-if="!enableSearch" @click="gotoProfile" title="Profile" :class="[
              { 'active-right-menu': nav === 'profile' },
              { noHover: nav !== '' && nav !== 'finish' },
            ]" class="pl-4 pr-4">
              <button type="button" class="btn p-0 m-0" style="width: 23px">
                <img src="../assets/Icons/user.svg" alt="Profile" />
              </button>
            </span>
            <span v-if="!enableSearch" title="Settings" class="pl-5 pr-5">
              <!--<button type="button" class="btn p-0 m-0" style="width: 23px">
              <img src="../assets/Icons/setting.svg" alt="Settings" />
            </button>-->
              <div class="header__inner" :style="
                this.$route.meta.navItem === 'Settings'
                  ? 'border-bottom: 5px solid #050505'
                  : 'border-bottom:0px;'
              " :class="[
  { 'menu-active': nav === 'Settings' },
  { noHover: nav !== '' && nav !== 'finish' },
]">
                <div v-if="!showZeeProduct">
                  <w-menu-item :dropdown="true" :dropdownList="settingsDropdowns" :text="'Settings'" :icon="
                    $route.meta.navItem === 'Settings' ? 'active-setting' : 'setting'
                  " :nav="'Settings'" ref="menuItem" @SelectedMenu="selectedMenu($event)">
                  </w-menu-item>
                </div>
              </div>
            </span>
          </div>
          <div v-if="!enableSearch" style="display: flex" @click="handleEnableSearch()">
            <span v-if="!showZeeProduct" title="Search" style="background-color: #050505; height: 70px; width: 127px"
              class="d-flex justify-center">
              <button type="button" class="btn" style="width: 100%">
                <img src="../assets/Icons/search.svg" alt="" v-if="nav !== 'finish'" :style="
                  nav !== 'nav' && nav !== 'globalsearch' && nav !== ''
                    ? 'opacity:0.5'
                    : 'opacity:1'
                " />
                <img v-else src="../assets/Icons/search.svg" alt="" />
              </button>
            </span>
          </div>
          <div v-else class="search-wrapper">
            <div title="Search" style="background-color: #050505; height: 70px; width: 100%"
              class="d-flex align-items-center">
              <img src="../assets/Icons/search.svg" alt="" width="24" height="24" class="search-icon"
                @click="handleEnableSearch()" />
              <b-form-input class="wideSearchInput" v-model="globalSearch"></b-form-input>
            </div>
            <div style="width: 85%" class="searchSuggestionBOX" v-if="globalSearch.length">
              <ul>
                <li @click="selectSuggestion(data)" v-for="(data, index) in sugggestions" :key="index">
                  {{ data }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <modal name="newNotification" :width="388" class="profile newnotification" @closed="hideNotification"
        :clickToClose="false">
        <div class="">
          <!-- Notification Details-->
          <div class="w-100">
            <div class="notificationBox pt-4">
              <div class="welcome align-start d-block">
                <div style="height: 46px">
                  <span class="name ml-4">Notifications</span>
                </div>
              </div>
              <w-close @close="cancleNotification"> </w-close>
            </div>
            <div style="overflow: auto; height: 91vh; font-size: 14px" class="pt-1">
              <div class="row content p-3 m-0" v-for="(notification, index) in notifications" :key="index">
                <div class="w-100" v-if="notification.status !== null">
                  <span v-if="
                    notification.status.toLowerCase() === 'InProgress'.toLowerCase()
                  ">Your datastream {{ notification.datastreamName }} is in
                    progress</span>
                  <span v-else-if="notification.status.toLowerCase() === 'success'">Your datastream {{
                      notification.datastreamName
                  }} has been
                    successfull</span>
                  <span v-else>Your datastream {{ notification.datastreamName }} has been
                    failed</span>
                </div>
                <div class="w-100 d-flex" style="justify-content: space-between" :style="
                  notifications.length - 1 === index && notification.length > 7
                    ? 'margin-bottom: 4rem'
                    : 'margin-bottom: 0px;'
                ">
                  <span class="lightColorText" style="font-size: 12px">17 min ago</span>
                  <span style="text-decoration: underline; cursor: pointer; color: #050505"
                    @click="viewNotification">View Details</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </modal>
      <modal name="profile" :scrollable="true" :width="388" class="profile" :height="'auto'" @closed="hideProfile"
        :clickToClose="false">
        <div class="container-fluid h-100" :style="showBlock === 'logoutConfirmation' ? 'height: 100vh;' : ''">
          <!-- User Details-->
          <div class="w-100 h-100" v-if="showBlock === 'profileDetails'">
            <div class="h-100 w-100" style="overflow: auto">
              <div class="row w-100">
                <div class="welcome ml-4 mt-4 align-start d-block">
                  <div style="height: 22px">
                    <span class="text ProximaNovaRegular">Hello,</span>
                  </div>
                  <div style="height: 46px">
                    <span class="name">{{ alternateUserName }}</span>
                  </div>
                </div>
                <w-close @close="cancleProfile"> </w-close>
              </div>
              <div class="row w-100 justify-center mt-3">
                <w-avatar :height="120" :width="120" :imageName="'newAvatar'"> </w-avatar>
              </div>
              <div class="row w-100 justify-center mt-3" style="font-size: 16px">
                <div class="editProfile ProximaNovaRegular content-center" @click="editProfile">
                  <span><i class="fas fa-edit"></i> Edit your profile</span>
                </div>
              </div>
              <div class="row w-100 mt-4">
                <div class="ml-4">
                  <span class="profileHeading ProximaNovaRegular">Name</span>
                  <br />
                  <span class="profileHeading" style="color: #222a37 !important"><b>{{ alternateUserName }}</b></span>
                </div>
              </div>
              <div class="row w-100 mt-4">
                <div class="ml-4">
                  <!-- <span class="profileHeading ProximaNovaRegular">Designation</span> -->
                  <!-- <br /> -->
                  <!-- <span class="profileHeading" style="color: #222a37 !important"
                    ><b>CEO</b></span
                  > -->
                </div>
              </div>
              <div class="row w-100 mt-4">
                <div class="ml-4">
                  <span class="profileHeading ProximaNovaRegular mb-2">Product Access</span>
                  <div class="row w-100 m-0">
                    <span v-for="(editData, index) in userData.productAccess" :key="index"
                      class="accessProduct mb-2 mr-2 column-data">{{ editData }}</span>
                  </div>
                </div>
              </div>
              <div class="row w-100 mt-4">
                <!-- <div class="ml-4">
                  <span class="profileHeading ProximaNovaRegular">Location</span>
                  <br />
                  <span class="profileHeading" style="color: #222a37 !important"
                    ><b>Bengaluru</b></span
                  >
                </div> -->
              </div>
              <div class="row w-100 mt-4">
                <div class="ml-4">
                  <span class="profileHeading ProximaNovaRegular">E-mail</span>
                  <br />
                  <span class="profileHeading" style="color: #222a37 !important"><b>{{ userData.email }}</b></span>
                </div>
              </div>
              <div class="row w-100 mt-4" style="margin-bottom: 65px">
                <div class="ml-4">
                  <span class="profileHeading ProximaNovaRegular">Password</span>
                  <br />
                  <span class="profileHeading" style="color: #222a37 !important"><b>********</b></span>
                </div>
              </div>
            </div>
            <div class="footer-logout w-100">
              <div class="row w-100 mr-0" style="height: 64px">
                <div class="w-100 p-4 logoutProfile" @click="logOutPrompt">
                  <span> <i class="fas fa-sign-in-alt"></i> Logout</span>
                </div>
              </div>
            </div>
          </div>
          <!-- Edit User Details-->
          <div class="w-100 mb-5" v-if="showBlock === 'editProgileDetail'">
            <div class="row">
              <div class="welcome ml-4 mt-4 align-start d-block">
                <div style="height: 46px">
                  <span class="name">Edit Profile</span>
                </div>
              </div>
              <w-close @close="cancleProfile"> </w-close>
            </div>
            <div class="row justify-center mt-3">
              <w-avatar :height="120" :width="120" :imageName="'newAvatar'"> </w-avatar>
            </div>
            <div class="row mt-4">
              <div class="ml-4 w-75">
                <w-input :type="'text'" :labelId="'username'" :labelText="'Name'" :userValue="editUserData.name"
                  @getInputValue="getUserName($event)"></w-input>
              </div>
            </div>
            <div class="row mt-4">
              <div class="ml-4">
                <span class="profileHeading ProximaNovaRegular">Designation</span>
                <br />
                <span class="profileHeading" style="color: #222a37 !important">
                  <!-- <b>CEO</b> -->
                </span>
              </div>
              <!--<div class="ml-4 w-75">
                <label for="designation" class="profileHeading"
                  >Designation</label
                >
                <br />
                <input
                  type="text"
                  value="Software Development Engineer"
                  id="designation"
                />
              </div>-->
            </div>
            <div class="row mt-4">
              <div class="ml-4">
                <span class="profileHeading mb-2 ProximaNovaRegular">Product Access</span>
                <br />
                <br />
                <div class="row w-100 m-0">
                  <span v-for="(editData, index) in editUserData.productAccess" :key="index"
                    class="accessProduct mb-2 mr-2 column-data">{{ editData }}</span>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <!--<div class="ml-4 w-75">
                <label for="location" class="profileHeading">Location</label>
                <br />
                <input type="text" value="Bengaluru" id="location" />
              </div>-->
              <div class="ml-4">
                <span class="profileHeading ProximaNovaRegular">Location</span>
                <br />
                <span class="profileHeading" style="color: #222a37 !important"><b>Bengaluru</b></span>
              </div>
            </div>
            <div class="row mt-4">
              <div class="ml-4">
                <span class="profileHeading ProximaNovaRegular">E-mail</span>
                <br />
                <span class="profileHeading" style="color: #222a37 !important"><b>{{ editUserData.email }}</b></span>
              </div>
            </div>
            <div class="row mt-4">
              <div class="ml-4 w-75">
                <w-input :type="passwordType" :labelId="'password'" :labelText="'Password'"
                  @getInputValue="getValue($event)"></w-input>
                <span class="hidePassword" v-if="passwordType === 'text'" @click="hidePassword"><img
                    src="../assets/eye-line.png" alt="" /></span>
                <span class="showPassword" style="font-size: 17px" v-if="passwordType === 'password'"
                  @click="showPassword">
                  <img src="../assets/eye-close-line.svg" alt="" />
                </span>
              </div>
            </div>
            <div class="row mt-4">
              <div class="ml-4 w-75">
                <w-input :class="{ incorrect: newpassword !== confirmPassword }" :type="passwordType"
                  :labelText="'Confirm Password'" :labelId="'passwordconfirm'" @getInputValue="getConfirmValue($event)">
                </w-input>

                <span class="far fa-check-circle confPassword" style="font-size: 17px"
                  v-if="newpassword === confirmPassword && confirmPassword !== ''"></span>
                <span v-if="newpassword !== confirmPassword" class="far fa-times-circle confPassword"
                  style="color: #ff5a5a !important; font-size: 17px"></span>
                <span v-if="newpassword !== confirmPassword"
                  style="color: #ff5a5a !important; font-size: small">Incorrect Password</span>
              </div>
            </div>
            <div class="row mt-4 d-flex mr-5 mb-4" style="float: right">
              <div class="button-group d-flex mb-5">
                <w-button class="cancle mr-2 px-5" :buttonText="'Cancel'" :isDisable="false" :buttonLoader="'none'"
                  @buttonClicked="cancleEditProfile">
                </w-button>
                <w-button :class="{ resetPasswordLink: disableUpdate }" :buttonLoader="update" :buttonText="'Update'"
                  :isDisable="disableUpdate" @buttonClicked="updateUserDetails">
                </w-button>
              </div>
            </div>
          </div>
          <!-- User Logout Details-->
          <div class="w-100 mt-4" v-if="showBlock === 'logoutConfirmation'">
            <div class="row">
              <w-close @close="cancleProfile"> </w-close>
            </div>
            <div class="row mt-5" style="text-align: center">
              <div class="w-100">
                <div>
                  <img src="../assets/Icons/logoutConfirm.svg" alt="logOut Confirm" />
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="w-100" style="text-align: center">
                <h2><b>Logout?</b></h2>
              </div>
              <div class="w-100 px-5 py-3 mb-5" style="
                  text-align: center;
                  color: #9aafd4;
                  font-family: ProximaNovaRegular;
                  font-size: 16px;
                ">
                <span>
                  Hey {{ alternateUserName }}, are you sure you want to logout of your
                  account?
                </span>
              </div>
              <div class="w-100 mt-4 mb-4" style="
                  text-align: center;
                  color: #fff;
                  font-family: ProximaNovaSemiBold;
                  font-size: 16px;
                ">
                <span @click="logOut" class="px-4 py-4" style="
                    background-color: #ff5a5a;
                    border-radius: 3px;
                    cursor: pointer;
                    font-size: 16px;
                  ">Yes, sure
                  <img src="../assets/Icons/logout-circle.svg" class="ml-5" alt="Logout" />
                </span>
              </div>
              <div class="w-100 mt-4 mb-4" style="
                  text-align: center;
                  font-family: ProximaNovaRegular;
                  color: #050505;
                  font-size: 16px;
                ">
                <span class="abortLogout" @click="cancleEditProfile">No. I change my mind</span>
              </div>
            </div>
          </div>
        </div>
      </modal>
      <modal name="newWelcomePage" :width="388" class="welcome--modal" @closed="hideWelcomePage" :clickToClose="false"
        v-show="isStartNewModal">
        <div class="">
          <div class="w-100">
            <div class="row my-5">
              <div class="col p-0 welcome align-start mt-5">
                <img src="../assets/group-21.png" alt class="welcome--image" />
              </div>
              <div class="col p-0 intro__content mt-5">
                <h3 class="ProximaNovaRegular Hello-user">Hello {{ userData.name }},</h3>
                <p class="Welcome-to-Data-Poem ProximaNovaBold my-4">
                  Welcome to Data Poem
                </p>
                <span class="Together-let-us-hel ProximaNovaRegular">
                  Together, let us help you to find your best recommended model for your
                  next campaign.
                </span>

                <w-button :buttonText="buttonText" class="my-4" @buttonClicked="letsStart"></w-button>
                <!--<button
                  color="primary isi"
                  class="btn-effect-intro"
                  size="button--size-m"
                  @click="letsStart"
                >
                  <span class="btn-text">Let's get started</span>
                  <i
                    class="fas fa-arrow-right ml-5 pl-4 d-flex"
                    style="align-items: center"
                  ></i>
                </button>-->
              </div>
            </div>
          </div>
        </div>
      </modal>
    </header>
    <w-intro v-if="!isWelcomeModal && nav !== 'finish' && $route.path === '/welcome'" :changeIntro="changeIntro">
    </w-intro>
    <m-solution ref="solutionModal" :optionList="solutionList"></m-solution>
  </div>
</template>
<script>
import Button from "@/components/Profile/Button.vue";
import Avatar from "@/components/Profile/Avatar.vue";
import Close from "@/components/Profile/Close.vue";
import Input from "@/components/Profile/Input.vue";
import MenuItem from "@/components/Profile/HeaderMenu.vue";
import NewNav from "@/components/Intro/NewNav.vue";
import { LoginServices } from "../services/LoginServices.js";
const loginServices = new LoginServices();
var timer;
import SolutionModal from "@/components/Modal/UserOnBoardingModal.vue";
export default {
  components: {
    "m-solution": SolutionModal,
    "w-button": Button,
    "w-avatar": Avatar,
    "w-close": Close,
    "w-input": Input,
    "w-menu-item": MenuItem,
    "w-intro": NewNav,
  },
  data() {
    return {
      showZeeProduct: false,
      noDataConnection: true,
      showDemoDropdown: true,
      hasUserAccess: true,
      userUsing: sessionStorage.getItem("organization").split("@")[0],
      userHasTheAccess: true,
      alternateUserName: sessionStorage.getItem("userName"),
      hostname: "",
      firstLogin: false,
      isItdUser: false,
      solutionDropdown: [
        // {
        //   name: "Agile",
        //   subMenu: [{ name: "Longer Horizone" }, { name: "Agile Horizone" }],
        // },
        // { name: "Attribution", subMenu: [] },

        // {
        //   name: "Digital Poem",
        //   subMenu: [{ name: "Agile" }, { name: "Longer Horizone" }],
        // },

        { name: "Health Monitor", subMenu: [] },
        { name: "Digital Planning", subMenu: [] },


        // { name: "Health Monitor", subMenu: [] },
        // {
        //   name: "AIMO",
        //   subMenu: [{ name: "Longer Horizone" }, { name: "Agile Horizone" }],
        // },
        // {
        //   name: "Agile + Attribution",
        //   subMenu: [{ name: "Longer Horizone" }, { name: "Agile Horizone" }],
        // },
        // {
        //   name: "Business At Glance",
        //   subMenu: [],
        // },
      ],
      solutionList: [
        { name: "Digital", value: "digital" },
        { name: "Upload Data", value: "uploadData" },
        { name: "DigitalPoem", value: "digitalpoem" },
      ],
      buttonText: "Let's get started",
      settingsDropdowns: [],
      demoDropdowns: [
        { name: "Health Monitor", subMenu: [] },
        { name: "Digital Planning", subMenu: [] },
        {
          name: "AIMO-USA",
          subMenu: [{ name: "Start New" }, { name: "Results" }],
        },
        {
          name: "AIMO-IN",
          subMenu: [{ name: "Start New" }, { name: "Result-IN" }, { name: "Result-HSM" }],
        },
        {
          name: "DP-Digital First",
          subMenu: [],
        },
        {
          name: "BFSI",
          subMenu: [],
        },
        {
          name: "Ecomm",
          subMenu: [],
        },
        {
          name: "DigitalPoem",
          subMenu: [{ name: "Start New" }, { name: "Results" }],
        },
        { name: "Growth", subMenu: [] },


      ],
      uploadDataDropdowns: ["Agile", "Attribution"],
      dataConnection: [
        { name: "Digital", subMenu: [] },
        // { name: "Omni Channel", subMenu: [] },
        { name: "Upload Data", subMenu: [] },
      ],
      toggleActive: false,
      userName: "User",
      notificationLength: "",
      notifications: [{ status: "success", datastreamName: "Test" }],
      makeCall: false,
      screenHeight: window.innerHeight,
      newpassword: "",
      confirmPassword: "",
      showBlock: "profileDetails",
      userData: {
        name: "User",
        email: "user@comapny.com",
        productAccess: ["AIMO"],
      },
      editUserData: {},
      passwordType: "password",
      update: "normal",
      hoveredSolution: false,
      hoveredUploadPlan: false,
      hoveredSavedPlan: false,
      isWelcomeModal: true,
      nav: "",
      route: "",
      clientId: "",
      orgNumber: "",
      enableSearch: false,
      globalSearch: "",
      sugggestions: [
        "Advert campaign 2021",
        "Advert campaign 2020",
        "Advert campaign 2018",
        "Advert campaign 2016 - 17",
        "Advert campaign 2019 - 20",
      ],
    };
  },
  computed: {
    isStartNewModal() {
      const modal =
        this.$route.name == "Welcome" && this.firstLogin === "true" ? true : false;
      return modal;
    },
    disableUpdate() {
      if (this.editUserData.name) {
        if (this.newpassword === this.confirmPassword && this.newpassword.length >= 4) {
          return false;
        }
        return true;
      }
      return true;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeEvent);
    clearInterval(timer);
  },
  mounted() {
    this.route = this.$route.path;

    this.showWelcomePage();
  },
  methods: {
    userAccessFinder() {
      if (this.userUsing === "chilin.tang") {
        this.userHasTheAccess = false;
      } else if (this.userUsing === "reevi.rajan") {
        this.userHasTheAccess = false;
      } else if (this.userUsing === "org3") {
        this.userHasTheAccess = false;
      }
    },
    getNotification() {
      loginServices
        .getNotification(this.orgNumber, this.clientId)
        .then((res) => {
          this.makeCall = res.makeCall;
          this.notifications = [];
          for (var i = 0; i < res.notifications.length; i++) {
            this.notifications.push({
              status: res.notifications[i].status,
              datastreamName: res.notifications[i].datastreamName,
              id: res.notifications[i].adverityDatastreamId,
            });
          }
          if (!this.makeCall) {
            clearInterval(timer);
          }
        })
        .catch(() => {
          clearInterval(timer);
        });
    },
    callNotification() {
      timer = setInterval(() => {
        var self = this;
        self.getNotification();
      }, 60 * 1000);
    },
    logOut() {
      localStorage.removeItem("_key");
      sessionStorage.clear();
      window.location.href = "/";
    },
    selectedMenu(data) {
      if (data.dropdown && data.subMenu === "") {
        return;
      } else if (data.dropdown && data.subMenu !== "") {
        if (data.subMenu === "Agile" && data.title === "Digital Poem") {
          this.$router.push("/solutions/digital-poem/kpi");
        } else if (data.subMenu === "Longer Horizone" && data.title === "Digital Poem") {
          this.$router.push("/solutions/digital-poem/kpi");
        } else if (data.subMenu === "Start New" && data.title === "AIMO-USA") {
          this.$router.push("/demo/usa/platform");
        } else if (data.subMenu === "Results" && data.title === "AIMO-USA") {
          sessionStorage.setItem("kpiMarket", JSON.stringify({ text: "USA", id: "usa" }));
          this.$router.push("/demo/usa/platform/models");
        } else if (data.subMenu === "Start New" && data.title === "DigitalPoem") {
          this.$router.push("/demo/digitalpoem/platform");
        } else if (data.subMenu === "Results" && data.title === "DigitalPoem") {
          sessionStorage.setItem(
            "kpiMarket",
            JSON.stringify({ text: "All India", id: "allindia" })
          );
          this.$router.push("/demo/digitalpoem/model");
        } else if (data.subMenu === "Start New" && data.title === "AIMO-IN") {
          this.$router.push("/demo/in/platform");
        } else if (data.subMenu === "Result-IN" && data.title === "AIMO-IN") {
          sessionStorage.setItem(
            "kpiMarketIndia",
            JSON.stringify({ text: "All India", id: "allindia" })
          );
          this.$router.push("/demo/in/platform/models");
          if (this.$route.path === "/demo/in/platform/models") {
            location.reload();
          }
        } else if (data.subMenu === "Result-HSM" && data.title === "AIMO-IN") {
          sessionStorage.setItem(
            "kpiMarketIndia",
            JSON.stringify({ text: "HSM", id: "hsm" })
          );
          this.$router.push("/demo/in/platform/models");
          if (this.$route.path === "/demo/in/platform/models") {
            location.reload();
          }
        } else if (data.subMenu === "Attribution" && data.title === "AIMO") {
          sessionStorage.setItem(
            "solutionsKpiMarket",
            JSON.stringify({ text: "All India", id: "allindia" })
          );
          this.$router.push("/solutions/in/platform/allstats");
          if (this.$route.path === "/solutions/in/platform/allstats") {
            location.reload();
          }
        }
        //for uniliver
        else if (data.subMenu === "Attribution" && data.title === "UL") {
          sessionStorage.setItem(
            "solutionsKpiMarket",
            JSON.stringify({ text: "All India", id: "allindia" })
          );
          this.$router.push("/solutions/ul/platform/");
          if (this.$route.path === "/solutions/ul/platform/attribution") {
            location.reload();
          }
        }
        else if (data.subMenu === "Attribution" && data.title === "Acko") {
          sessionStorage.setItem(
            "solutionsKpiMarket",
            JSON.stringify({ text: "All India", id: "allindia" })
          );
          this.$router.push("/solutions/acko/platform/");
          if (this.$route.path === "/solutions/acko/platform/attribution") {
            location.reload();
          }

        }
        else if (data.subMenu === "Attribution" && data.title === "Skoda") {
          sessionStorage.setItem(
            "solutionsKpiMarket",
            JSON.stringify({ text: "All India", id: "allindia" })
          );
          this.$router.push("/solutions/dp/platform/");
          if (this.$route.path === "/solutions/dp/platform/attribution") {
            location.reload();
          }
        }
        else if (data.subMenu === "Attribution" && data.title === "UL") {
          sessionStorage.setItem(
            "solutionsKpiMarket",
            JSON.stringify({ text: "All India", id: "allindia" })
          );

          this.$router.push("/solutions/ul/platform/models");
          if (this.$route.path === "/solutions/ul/platform/models") {
            location.reload();
          }
        } else if (data.subMenu === "Input Data Summary" && data.title === "AIMO") {
          this.$router.push("/solutions/in/platform");
        }
        else if (data.subMenu === "Attribution" && data.title === "AIMO DP") {
          this.$router.push("/solutions/dp/platform");
        }
        else if (data.subMenu === "Input Data Summary" && data.title === "Acko") {
          this.$router.push("/data-connection-platform/digital");
        }
        else if (data.subMenu === "Causal Insights" && data.title === "UL") {
          this.$router.push("/solutions/ul/platform/insights");
        } else if (data.subMenu === "ROI Summary" && data.title === "UL") {
          this.$router.push("/solutions/ul/platform/roi-summary");
        } else if (data.subMenu === "ROI Summary" && data.title === "Acko") {
          this.$router.push("/solutions/acko/platform/roi-summary");
        }
        else if (data.subMenu === "ROI Summary" && data.title === "Skoda") {
          this.$router.push("/solutions/dp/platform/roi-summary");
        } else if (data.subMenu === "Future Planning" && data.title === "UL") {
          this.$router.push("/solutions/dp/platform/future-planning");
        } else if (data.subMenu === "Future Planning" && data.title === "Acko") {
          this.$router.push("/solutions/ul/bbo-results");
        } else if (data.subMenu === "Generate a new Plan" && data.title === "ZEE") {
          this.$router.push("/solutions/zeetv/select-model");
        } else if (data.subMenu === "Program Outputs" && data.title === "ZEE") {
          this.$router.push("/solutions/zeetv/program-outputs");
        }
        return;
      }
      var menu = data.title;
      if (menu === "Attribution") {
        this.$router.push("/solutions");
      }
      if (menu === "Growth") {
        this.$router.push("/growth/platform");
      }
      if (menu === "Saved Plans") {
        this.$router.push("/saved-plans");
      }
      if (menu === "AIMO-USA") {
        this.$router.push("/demo/usa/platform");
      }
      if (menu === "DigitalPoem") {
        this.$router.push("/demo/digitalpoem/platform");
      }
      if (menu === "Digital Poem") {
        this.$router.push("/solutions/digital-poem/kpi");
      }
      if (menu === "Upload Data") {
        this.$router.push("/upload-data/agile");
      }
      if (menu === "Agile") {
        this.$router.push("/solutions/agile");
      }
      if (menu === "AIMO") {
        this.$router.push("/solutions/AIMO");
      }
      if (menu === "Digital") {
        // this.$router.push("/data-connection-platform/welcome");
        this.$router.push("/data-connection-platform/digital");
      }
      if (menu === "Manage Users") {
        this.$router.push("/settings/users");
      }
      if (menu === "Documentation") {
        loginServices.getDocumentation().then((res) => {
          window.open(res, "_blank");
        });
      }
      if (menu === "Manage Brands") {
        this.$router.push("/settings/brands");
      }
      if (menu === "Reauthorize") {
        this.$router.push("/connection/re-auth");
      }
      if (menu === "Omni Channel") {
        this.$router.push("/data-connection-platform/digital");
      }
      if (menu === "Health Monitor") {
        this.$router.push("/solutions/health-monitor");
      }
      if (menu === "Digital Planning") {
        this.$router.push("/digital-planning");
      }
      if (menu === "DP-Digital First") {
        this.$router.push("/demo/aimo-hospitality/model-result");
      }
      if (menu === "BFSI") {
        this.$router.push("/demo/bfsi/model-result");
      }
      if (menu === "Ecomm") {
        this.$router.push("/demo/ecomm/model-result");
      }
      if (menu === "ZEE") {
        this.$router.push("/solutions/zeetv/select-model");
      }
      if (menu === "Agile + Attribution") {
        this.$router.push("/solutions/agile-attribution");
      }
      if (menu === "Business At Glance") {
        this.$router.push("/solutions/data-summary");
      }
      if (menu === "Switch") {
        this.$router.push("/settings/switch/user");
      }
      if (menu === "Cost Multiplier") {
        this.$router.push("/settings/cost-multiplier");
      }
    },
    getUserName(e) {
      this.editUserData.name = e;
    },
    getValue(e) {
      this.newpassword = e;
    },
    getConfirmValue(e) {
      this.confirmPassword = e;
    },
    resizeEvent() {
      this.screenHeight = window.innerHeight;
    },
    gotoHome() {
      this.$router.push("/welcome");
    },
    gotoProfile() {
      this.showProfile();
    },

    sidebarToggle() {
      this.toggleActive = !this.toggleActive;
      this.$emit("toggleSidebar", this.toggleActive);
    },
    closeModal() {
      this.$modal.hide("notification");
      this.getNotificationDetails();
    },
    showModal() {
      this.showNotification();
    },
    onHidden() {
      this.closeModal();
    },
    // Profile Events
    showPassword() {
      this.passwordType = "text";
    },
    hidePassword() {
      this.passwordType = "password";
    },
    cancleNotification() {
      this.hideNotification();
    },
    viewNotification() {
      // this.$router.push("/user-dsv/datastreamsummary");
      console.log("view-notification");
    },
    cancleEditProfile() {
      this.showBlock = "profileDetails";
    },
    editProfile() {
      this.showBlock = "editProgileDetail";
    },
    logOutPrompt() {
      this.showBlock = "logoutConfirmation";
    },
    showProfile() {
      this.$modal.show("profile");
    },
    hideProfile() {
      this.showBlock = "profileDetails";
      this.$modal.hide("profile");
    },
    hideNotification() {
      this.$modal.hide("newNotification");
    },
    showNotification() {
      this.$modal.show("newNotification");
    },
    cancleProfile() {
      this.hideProfile();
    },
    logout() {
      sessionStorage.clear();
      this.$router.push("/");
    },
    getNotificationDetails() {
      // this.notifications = [];
      // connectionService.getNotification().then((res) => {
      //   this.notifications = res.notifications;
      //   this.notificationLength = res.totalNotifications;
      //   this.makeCall = res.makeCall;
      // });
    },
    updateUserDetails() {
      var userData = {
        userName: this.editUserData.name,
        password: this.confirmPassword,
        id: sessionStorage.getItem("userId"),
      };
      loginServices.updateUser(userData).then((res) => {
        console.log(res);
        sessionStorage.setItem("userName", userData.userName);
        this.hideProfile();
      });
    },
    hideWelcomePage() {
      this.$modal.hide("newWelcomePage");
    },
    showWelcomePage() {
      this.$modal.show("newWelcomePage");
    },
    letsStart() {
      this.isWelcomeModal = false;
      this.nav = "nav";
      this.$modal.hide("newWelcomePage");
    },
    changeIntro(e) {
      this.nav = e;
      this.$refs.menuItem.selectMenu(e);
      if (e === "finish") {
        this.$refs.solutionModal.open("Solutions");
      }
    },
    handleEnableSearch() {
      this.enableSearch = !this.enableSearch;
      this.globalSearch = "";
    },
    selectSuggestion(val) {
      this.globalSearch = val;
    },
    checkDemoAccess() {
      if (sessionStorage.getItem("clientId") === "access@datapoem.com" || sessionStorage.getItem("clientId") === "bharath@datapoem.com") {
        this.showDemoDropdown = true
      }
      else {
        this.showDemoDropdown = false
      }
    }
  },
  created() {
    this.checkDemoAccess()
    this.hostname = window.location.host;
    window.addEventListener("resize", this.resizeEvent);
    this.userData.name = sessionStorage.getItem("userName");
    this.userData.email = sessionStorage.getItem("clientId");
    this.userData.productAccess = JSON.parse(sessionStorage.getItem("tools"));
    this.orgNumber = sessionStorage.getItem("subId");
    this.clientId = sessionStorage.getItem("clientId");
    this.editUserData = this.userData;
    // this.getNotificationDetails();
    this.callNotification();
    this.userAccessFinder();

    // localStorage.setItem("selectedBrandKPI", "Tresemme");
    // localStorage.setItem("selectedMediaKPI", "Overall");

    // if (sessionStorage.getItem("subId") === "zbtkqrqkajmrvukdqbkc") {
    //   this.solutionDropdown.push({
    //     name: "ZEE",
    //     subMenu: [{ name: "Generate a new Plan" }, { name: "Program Outputs" }],
    //   });
    // }

    console.log(sessionStorage.getItem("clientId").split("@")[1] === "zee.com")

    if (sessionStorage.getItem("clientId").split("@")[1] === "zee.com" || sessionStorage.getItem("clientId") === "sathya@datapoem.com") {
      this.solutionDropdown = [];
      this.dataConnection = [];

      console.log(this.dataConnection)

      this.demoDropdowns = [];
      this.showZeeProduct = true;
      // this.solutionDropdown.push({
      //   name: "ZEE",
      //   subMenu: [{ name: "Generate a new Plan" }, { name: "Program Outputs" }],
      // });
    }

    if (sessionStorage.getItem("subId") === "drhvmjfwqfakywcsggpd") {
      this.settingsDropdowns = [
        {
          name: "Manage Brands",
          subMenu: [],
        },
        {
          name: "Reauthorize",
          subMenu: [],
        },
      ];
      this.solutionDropdown = [];
      this.solutionDropdown.push({ name: "Digital Planning", subMenu: [] });
      this.solutionDropdown.push({ name: "Health Monitor", subMenu: [] });
      this.firstLogin = false;
      this.isItdUser = true;
    } else {
      this.isItdUser = false;
      if (sessionStorage.getItem("Role") === "MASTER_ADMIN") {
        this.settingsDropdowns = [
          {
            name: "Manage Users",
            subMenu: [],
          },
          {
            name: "Manage Brands",
            subMenu: [],
          },
          {
            name: "Reauthorize",
            subMenu: [],
          },
        ];
      } else {
        this.settingsDropdowns = [
          {
            name: "Manage Brands",
            subMenu: [],
          },
          {
            name: "Reauthorize",
            subMenu: [],
          },
        ];
      }
      if (sessionStorage.getItem("parentEmail") !== null) {
        if (sessionStorage.getItem("parentEmail").split("@")[1] === "datapoem.com") {
          // this.solutionDropdown.push({ name: "Digital Planning", subMenu: [] });
          // this.solutionDropdown.push({ name: "ZEE", subMenu: [] });

          // this.solutionDropdown.push({
          //   name: "AIMO",
          //   subMenu: [
          //     // { name: "Input Data Summary" },
          //     { name: "Attribution" },
          //   ],
          // });

          // this.solutionDropdown.push({
          //   name: "AIMO DP",
          //   subMenu: [
          //     // { name: "Input Data Summary" },
          //     { name: "Attribution" },
          //   ],
          // });

          // this.solutionDropdown.push({
          //   name: "UL",
          //   subMenu: [
          //     // { name: "Input Data Summary" },
          //     { name: "Attribution" },
          //   ],
          // });
          this.settingsDropdowns.push({
            name: "Switch",
            subMenu: [],
          });

          this.settingsDropdowns.push({
            name: "Documentation",
            subMenu: [],
          });
          this.settingsDropdowns.push({
            name: "Cost Multiplier",
            subMenu: [],
          });
        }
      }

      if (sessionStorage.getItem("clientId") !== null) {
        if (sessionStorage.getItem("clientId").split("@")[0] === "acko.datapoem") {
          // this.solutionDropdown.push({ name: "Digital Planning", subMenu: [] });
          // this.solutionDropdown.push({ name: "ZEE", subMenu: [] });

          // this.solutionDropdown.push({
          //   name: "Acko",
          //   subMenu: [
          //     { name: "Input Data Summary" },
          //     { name: "Attribution" },
          //     { name: "Future Planning" },
          //     { name: "ROI Summary" },
          //   ],
          // });
          this.settingsDropdowns.push({
            name: "Switch",
            subMenu: [],
          });

          this.settingsDropdowns.push({
            name: "Documentation",
            subMenu: [],
          });
          this.settingsDropdowns.push({
            name: "Cost Multiplier",
            subMenu: [],
          });
        }
      }

      if (sessionStorage.getItem("clientId") !== null) {
        if (sessionStorage.getItem("clientId").split("@")[0] === "datapoem.skoda") {
          // this.solutionDropdown.push({ name: "Digital Planning", subMenu: [] });
          // this.solutionDropdown.push({ name: "ZEE", subMenu: [] });

          // this.solutionDropdown.push({
          //   name: "Skoda",
          //   subMenu: [
          //     // { name: "Input Data Summary" },
          //     { name: "Attribution" },
          //     { name: "ROI Summary" },

          //   ],
          // });
          this.settingsDropdowns.push({
            name: "Switch",
            subMenu: [],
          });

          this.settingsDropdowns.push({
            name: "Documentation",
            subMenu: [],
          });
          this.settingsDropdowns.push({
            name: "Cost Multiplier",
            subMenu: [],
          });
        }
      }

      if (
        sessionStorage.getItem("organization").split("@")[0] === "org3" ||
        this.userUsing === "chilin.tang" ||
        this.userUsing === "reevi.rajan"
      ) {
        const solutionsIndex1 = this.solutionDropdown.filter((item) =>
          item.name.includes("Digital Poem")
        );

        const solutionsIndex9 = this.solutionDropdown.filter((item) =>
          item.name.includes("AIMO")
        );

        if (solutionsIndex9.length === 0) {
          this.solutionDropdown.push({
            name: "AIMO",
            subMenu: [
              // { name: "Input Data Summary" },
              { name: "Attribution" },
            ],
          });
        }

        if (solutionsIndex1[0].name === "Digital Poem") {
          this.solutionDropdown.splice(0, 1);
        }

        const solutionsIndex2 = this.solutionDropdown.filter((item) =>
          item.name.includes("Health Monitor")
        );

        const solutionsIndex3 = this.solutionDropdown.filter((item) =>
          item.name.includes("Digital Planning")
        );

        const solutionsIndex4 = this.solutionDropdown.filter((item) =>
          item.name.includes("UL")
        );

        const solutionsIndex5 = this.solutionDropdown.filter((item) =>
          item.name.includes("ZEE")
        );

        if (solutionsIndex2[0].name === "Health Monitor") {
          this.solutionDropdown.splice(0, 1);
        }

        if (solutionsIndex3[0].name === "Digital Planning") {
          this.solutionDropdown.splice(1, 1);
        }

        if (solutionsIndex4[0].name === "UL") {
          this.solutionDropdown.splice(2, 1);
        }
        if (solutionsIndex5[0].name === "ZEE") {
          this.solutionDropdown.splice(3, 1);
        }
      }

      if (sessionStorage.getItem("organization").split("@")[0] === "org3") {
        const settingsIndex1 = this.settingsDropdowns.filter((item) =>
          item.name.includes("Documentation")
        );

        const settingsIndex2 = this.settingsDropdowns.filter((item) =>
          item.name.includes("Cost Multiplier")
        );

        if (settingsIndex1[0].name === "Documentation") {
          this.settingsDropdowns.splice(4, 1);
        }

        if (settingsIndex2[0].name === "Cost Multiplier") {
          this.settingsDropdowns.splice(4, 1);
        }
      }

      if (sessionStorage.getItem("organization") !== null) {
        if (
          sessionStorage.getItem("organization").split("@")[0] === "datapoem.unilever"
        ) {
          // this.solutionDropdown.push({
          //   name: "UL",
          //   subMenu: [
          //     // { name: "Input Data Summary" },
          //     // { name: "Attribution" },
          //     // { name: "Causal Insights" },
          //     // { name: "ROI Summary" },
          //     { name: "Future Planning" },
          //   ],
          // });
        }
      }

      if (sessionStorage.getItem("organization") !== null) {
        if (sessionStorage.getItem("clientId").split("@")[1] === "mindshareworld.com") {
          this.userHasTheAccess = false;
          this.noDataConnection = false;

          const index_1 = this.solutionDropdown.filter((item) =>
            item.name.includes("Digital Poem")
          );

          const index_2 = this.solutionDropdown.filter((item) =>
            item.name.includes("Health Monitor")
          );

          const index_3 = this.solutionDropdown.filter((item) =>
            item.name.includes("Business At Glance")
          );

          if (index_1[0].name === "Digital Poem") {
            this.solutionDropdown.splice(0, 1);
          }

          if (index_2[0].name === "Health Monitor") {
            this.solutionDropdown.splice(0, 1);
          }

          if (index_3[0].name === "Business At Glance") {
            this.solutionDropdown.splice(0, 1);
          }
        }
      }

      if (sessionStorage.getItem("organization") !== null) {
        if (sessionStorage.getItem("clientId").split("@")[0] === "sunando.das") {
          // this.hasUserAccess = false
          this.userHasTheAccess = false;
          this.noDataConnection = false;

          const index_1 = this.solutionDropdown.filter((item) =>
            item.name.includes("Digital Poem")
          );

          const index_2 = this.solutionDropdown.filter((item) =>
            item.name.includes("Health Monitor")
          );

          const index_3 = this.solutionDropdown.filter((item) =>
            item.name.includes("Business At Glance")
          );

          if (index_1[0].name === "Digital Poem") {
            this.solutionDropdown.splice(0, 1);
          }

          if (index_2[0].name === "Health Monitor") {
            this.solutionDropdown.splice(0, 1);
          }

          if (index_3[0].name === "Business At Glance") {
            this.solutionDropdown.splice(0, 1);
          }

          // this.solutionDropdown.push({
          //   name: "UL",
          //   subMenu: [
          //     // { name: "Input Data Summary" },
          //     // { name: "Attribution" },
          //     // { name: "Causal Insights" },
          //     // { name: "ROI Summary" },
          //     { name: "Future Planning" },
          //   ],
          // });
        }
      }
      this.firstLogin = sessionStorage.getItem("firstLogin");
    }
  },
};
</script>
<style scoped>
.search-wrapper {
  width: 388px;
}

.search-icon {
  margin: 0 20px;
}

.incorrect>input[type="text"],
.incorrect>input[type="password"] {
  border-bottom: 1.5px solid rgb(255, 90, 90) !important;
  color: #ff5a5a !important;
}

.cancle.button.update {
  background-color: transparent;
  color: #050505;
}

.cancle.button.update:hover {
  color: #fff;
}

* {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

.modal-body .card {
  margin-top: 35px;
}

.container {
  width: 1000px;
  margin: 0 auto;
}

/* .header {
  padding: 12px 0;
  background: #ccc;
  transition: all 0.3s
} */
.badge {
  position: relative;
  top: -11px;
  left: -10px;
  line-height: 0px;
  background: #000;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  font-weight: bold;
}

button {
  margin: 5px;
}

.header {
  width: 100%;
  height: 70px;
  background-color: #ffffff;
  color: #050505;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 15%);
}

.header_z_index {
  z-index: 999;
}

.header_z_index_more {
  /* z-index: 1000; */
  z-index: 99;
}

.header__inner {
  height: 70px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

input[type="text"],
input[type="password"] {
  border: 0;
  outline: 0;
  border-bottom: 1.5px solid #050505;
  color: #222a37;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
}

.confPassword {
  float: right;
  margin-right: 6px;
  margin-top: -20px;
  position: relative;
  z-index: 2;
  color: #2cc84d;
}

.hidePassword {
  /* background: url(../assets/Icons/hide-password.svg);*/
  float: right;
  background-repeat: no-repeat;
  margin-top: -20px;
  position: relative;
  z-index: 2;
  height: 20px;
  width: 26px;
  cursor: pointer;
}

.showPassword {
  float: right;
  margin-top: -20px;
  position: relative;
  z-index: 2;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.abortLogout {
  text-decoration: underline;
}

.abortLogout:hover {
  text-decoration: underline;
  color: #5aa7ff;
  cursor: pointer;
}

.notificationBox {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 99;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #fff inset !important;
}

.row.content {
  background-color: #eff5ff;
  border-bottom: 1px solid #fff;
}

.wideSearchInput {
  width: 85%;
  background-color: #050505 !important;
  color: #ffffff !important;
  font-size: 16px !important;
  height: 70px !important;
  outline: none !important;
}

.wideSearchInput:focus {
  width: 85%;
  background-color: #050505 !important;
  color: #ffffff !important;
  font-size: 16px !important;
  height: 70px !important;
  font-family: ProximaNovaRegular;
  box-shadow: none;
}

.searchSuggestionBOX ul {
  list-style: none;
  font-size: 16px;
  font-family: ProximaNovaRegular;
  cursor: pointer;
  background-color: #fff;
}

.searchSuggestionBOX ul li {
  line-height: 40px;
  padding: 12px 16px;
}

.searchSuggestionBOX ul li:hover {
  background-color: #eff5ff;
  font-family: ProximaNovaBold;
}
</style>
<style>
.sticky+.page-content {
  padding-top: 60px;
}

.editProfile :hover {
  text-decoration: underline;
  color: #5aa7ff;
  cursor: pointer;
}

.editProfile {
  text-decoration: underline;
}

.logoutProfile {
  background: #f8d8d8;
  color: #ff5a5a;
  cursor: pointer;
  font-size: 16px;
}

.modal-padding {
  padding: 100px 0;
}

.welcome--modal .vm--modal {
  width: 50% !important;
  height: auto !important;
  box-shadow: rgb(201 206 213) 0px 0px 8px 0px;
  background-color: var(--white);
  margin: auto;
  display: block;
  top: 0 !important;
  left: auto !important;
}

.welcome--modal .vm--overlay {
  background: rgba(0, 0, 0, 0.9) !important;
}

.welcome {
  justify-content: center;
  align-items: center;
  display: flex;
}

.intro__content {
  flex-direction: column;
  display: flex;
  align-items: flex-start;
}

.welcome--modal .welcome--image {
  width: 278px;
  height: 276px;
  object-fit: contain;
}

.Hello-user {
  color: #222a37;
}

.Welcome-to-Data-Poem {
  width: 100%;
  font-size: x-large;
}

.Together-let-us-hel {
  width: 90%;
  height: 54px;
  margin: 18px 0 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  color: var(--darke-text);
}

.btn-effect-intro {
  font-family: ProximaNovaRegular;
  background-color: #050505;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
  width: 240px !important;
  height: 60px !important;
  margin-top: 44px !important;
  object-fit: contain;
}

.accessProduct {
  padding: 8px;
  background-color: #deffe6;
  border: 1px solid #81f89e;
  width: auto !important;
}

.column-data {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #222a37;
  font-weight: normal;
}

.btn-effect-intro i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}

.btn-text {
  width: 138px;
  height: 22px;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.56px;
}

.menu-active {
  background: #fff;
}

.active-right-menu {
  height: 100%;
  background: #fff;
  align-items: center;
  display: flex;
}

.bg-class {
  background: rgba(0, 0, 0, -0.1) !important;
}

.bg-white {
  background: #fff !important;
}

.footer-logout {
  position: absolute;
  bottom: 0;
}

.menuList {
  display: flex;
}

@media screen and (max-width: 780px) {
  .menuList {
    display: block;
  }
}
</style>
